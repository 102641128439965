<template>
    <v-container>
        <div>
            <PageDeveloperCompaniesDetailv2/>
        </div>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import PageDeveloperCompaniesDetailv2 from '../developer/PageDeveloperCompaniesDetailv2.vue';

export default {
    components: {
        PageDeveloperCompaniesDetailv2,
    },
    computed: mapState({
        
    }),
    props: [
        //
    ],
    data: () => ({
        //
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        }
    }),
    created() {
        //
    },
    mounted() {
        //
    },
    methods: {
    }
}
</script>